import { createApp } from 'vue'
import PrimeVue from 'primevue/config'
import App from './App.vue'
import router from './router'
//import Aura from '@primevue/themes/aura'
//import Lara from '@primevue/themes/lara'

import Button from "primevue/button"
import Menubar from "primevue/menubar"
import ScrollPanel from 'primevue/scrollpanel';


const app = createApp(App);
app.use(PrimeVue);
app.use(router)
app.component('PrimeButton', Button)
app.component('MenuBar', Menubar)
app.component('ScrollPanel', ScrollPanel)

app.mount('#app')
