import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import ChapterView from '../components/ChapterView.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/chapter/:chapterId',
    name: 'ChapterView',
    component: ChapterView
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;